import React, {useState} from 'react'

export const CartContext = React.createContext()

export const ProductProvider = (props)=> {
  const [cart, setCart] = useState([])

  const updateCart = (product)=>{
    setCart(prevState => {
      return {...prevState, ...product};
    });
    console.log(cart)
  }

  return (
    <CartContext.Provider   
      value={{cart, updateCart}}
    >
      {props.children}
    </CartContext.Provider>
  )
}
