import './App.css';
// import Head from "./components/Head"
// import ProductList from "./components/ProductList"
// import CartCounter from "./components/Cartcounter"
// import Carousel from "./components/Carousel"
import {ProductProvider} from "./providers/productProvider"
import Inprogress from "./components/Inprogress"

function App() {
  return (
    <div className="container-fluid">
      <div className="row gx-0">
        <div className="col-sm-6 col-lg-9  px-0 mx-auto text-center">
          
          <ProductProvider>
            <Inprogress />
            {/* <Head/>
            <CartCounter />
            <Carousel />
            <ProductList /> */}
          </ProductProvider>
        </div>
      </div>
    </div>
  );
}

export default App;
